import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import classNames from 'classnames';

import Layout from '../components/Layout';
import SectionFeature from '../components/sections/SectionFeature';
import SectionEarlyAccess from '../components/sections/SectionEarlyAccess';

import useResizeScreen from '../hooks/useResize';

import logoElastic from '../assets/images/icons/logo-full-svg/elastic.svg';
import logoOpenTelemetry from '../assets/images/icons/logo-full-svg/open-telemetry.svg';
import logoPrometheus from '../assets/images/icons/logo-full-svg/prometheus.svg';
import iconIntegration from '../assets/images/icons/general-svg/icon-integration.svg';

const ApiBuilderPage = () => {
  const { isMobile } = useResizeScreen();
  return (
    <Layout title="Jetic - Design and Build APIs">
      <div className="api-builder-page">
        <section className="first-section first-section--common">
          <div className="container pt-4">
            <div className="row">
              <div className="col-md-6">
                <div className="first-section__content">
                  <h1 className="header-700 mb-4">API Builder</h1>
                  <h2 className="body-300 gray-500 mb-32">
                    Design, implement and deliver containerized REST APIs at scale
                  </h2>
                  <div className="btn-wrapper mb-3 pb-3">
                    <a
                      href="https://app.jetic.io/registration"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="c-btn c-btn--primary"
                    >
                      Sign up
                    </a>
                    <a href="https://docs.jetic.io/docs/Components/api_builder" className="c-btn c-btn--white">
                      Documentation
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                {!isMobile ? (
                  <StaticImage
                    src="../assets/images/pages/api-builder/Illustration-main.png"
                    className="first-section__image illustration w-100"
                    alt="API Builder"
                  />
                ) : (
                  <StaticImage
                    src="../assets/images/pages/api-builder/Illustration-main--mobile.png"
                    className="first-section__image illustration w-100"
                    alt="API Builder"
                  />
                )}
              </div>
            </div>
          </div>
        </section>

        <section className="c-section--primary">
          <div className="container">
            <div className="row">
              <h3 className="header-600 mb-4">From Zero to API in under 60 seconds</h3>
              <div className="col-md-5">
                <ul className="list-disc body-300">
                  <li>Quickly design OpenAPI compliant REST APIs</li>
                  <li>Implement reliable orchestration and routing logic</li>
                  <li>Expose your service as containerized, scalable API</li>
                </ul>
              </div>
              <div className="col-md-6 offset-md-1 mt-4 mt-md-0">
                <p className="body-300">
                  Visually create standardized OpenAPI conform REST APIs or import existing
                  specifications. After implementation the framework will automatically assemble the
                  container, and make sure all necessary configurations - such as service and ingress -  get wired properly.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="c-section--white">
          <div className="container">
            <SectionFeature
              sectionClassName="pb-80"
              contentLabelText="Design"
              contentTitleText="Visual REST API Designing"
              contentBodyTags={
                <>
                  <p className="body-300 gray-400 mb-4">
                   Use the visual API Builder to easily define a standardized API contract 
                   or import from existing OpenAPI specifications. Handle all configuration 
                   settings directly from within the tool.
                  </p>
                </>
              }
              contentImage={{
                tag: !isMobile ? (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/api-builder/Illustration-design.png"
                    alt="Visual REST API Designing"
                  />
                ) : (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/api-builder/Illustration-design--mobile.png"
                    alt="Visual REST API Designing"
                  />
                ),
              }}
            />

            <SectionFeature
              sectionClassName="py-80"
              contentLabelText="Implement"
              contentTitleText="Fastest API Implementation"
              contentBodyTags={
                <>
                  <p className="body-300 gray-400 mb-4">
                    Use drag-and-drop to start with the implementation of your REST API. Query
                    databases, call other APIs or use any of the 300+ Camel connectors to get and
                    manipulate the data you need.
                  </p>
                </>
              }
              contentImage={{
                tag: !isMobile ? (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/api-builder/Illustration-implement.png"
                    alt="Fastest API Implementation"
                  />
                ) : (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/api-builder/Illustration-implement--mobile.png"
                    alt="Fastest API Implementation"
                  />
                ),
              }}
            />

            <SectionFeature
              sectionClassName="py-80"
              contentLabelText="Expose & Scale"
              contentTitleText="Worry-free Service & Ingress Deployment"
              contentBodyTags={
                <>
                  <p className="body-300 gray-400 mb-4">
                    Once designed and implemented the API can be deployed and auto-configured on
                    Kubernetes with the click of a button. Within seconds, a fully automated,
                    containerized docker container exposes your API - ready to call.
                  </p>
                </>
              }
              contentImage={{
                tag: !isMobile ? (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/api-builder/Illustration-export-and-scale.png"
                    alt="Worry-free Service & Ingress Deployment"
                  />
                ) : (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/api-builder/Illustration-export-and-scale--mobile.png"
                    alt="Worry-free Service & Ingress Deployment"
                  />
                ),
              }}
            />

            <SectionFeature
              sectionClassName="pt-80"
              contentLabelText="3rd Party"
              contentTitleText="Monitor Metrics and Performance"
              contentBodyTags={
                <>
                  <p className="body-300 gray-400 mb-4">
                    We make it easy to expose data and metrics from your API in real-time to almost
                    any modern observability tool. We natively fit into stacks like Prometheus for
                    metrics, Elastic for logs, or OpenTelemetry for observability.
                  </p>
                  <div
                    className={classNames('d-flex align-items-center', {
                      'flex-column': isMobile,
                    })}
                  >
                    <img src={logoElastic} alt="Elastic" />
                    <img className="mx-md-4" src={logoPrometheus} alt="Prometheus" />
                    <img src={logoOpenTelemetry} alt="OpenTelemetry" />
                  </div>
                </>
              }
              contentImage={{
                tag: !isMobile ? (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/api-builder/Illustration-3rd-party.png"
                    alt="Monitor Metrics and Performance"
                  />
                ) : (
                  <StaticImage
                    className="illustration mt-4"
                    src="../assets/images/pages/api-builder/Illustration-3rd-party--mobile.png"
                    alt="Monitor Metrics and Performance"
                  />
                ),
              }}
            />
          </div>
        </section>

        <section className="c-section--gray py-80">
          <div className="container">
            <h3 className="header-600 text-center mb-0">
              Manage the full lifecyle of your APIs. From Zero to Hero API.
            </h3>
          </div>
        </section>

        <section className="py-80" style={{ boxShadow: 'inset 0px -1px 0px #E8E9EA' }}>
          <div className="container">
            <div className="row mb-80">
              <div className="col-lg-11 mx-auto">
                {!isMobile ? (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/api-builder/Illustration-vb.png"
                    alt="image"
                  />
                ) : (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/api-builder/Illustration-vb--mobile.png"
                    alt="image"
                  />
                )}
              </div>
            </div>
          </div>
        </section>

        <section className="py-80">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 mx-auto">
                <div className="row">
                  <div
                    className={classNames('col-md-6 col-lg-5 d-flex align-items-center', {
                      'flex-wrap': isMobile,
                      'justify-content-center': isMobile,
                    })}
                  >
                    {isMobile && (
                      <img
                        className="mb-4"
                        src={iconIntegration}
                        alt="Build trusted, reliable Data-as-a-Service Platforms."
                        height={48}
                        width={48}
                      />
                    )}
                    <h3
                      className={classNames('header-600', {
                        'text-center': isMobile,
                      })}
                    >
                      Build trusted, reliable Data-as-a-Service Platforms.
                    </h3>
                  </div>
                  {!isMobile && (
                    <div className="col-md-6 offset-lg-1">
                      <StaticImage
                        className="illustration"
                        src="../assets/images/pages/api-builder/Illustration-daasp.png"
                        alt="Build trusted, reliable Data-as-a-Service Platforms."
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <SectionEarlyAccess />
      </div>
    </Layout>
  );
};

export default ApiBuilderPage;
